@font-face {
  font-family: "bf-icon";
  src: url("../fonts/icomoon.eot?yemmms");
  src: url("../fonts/icomoon.eot?yemmms#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?yemmms") format("truetype"),
    url("../fonts/icomoon.woff?yemmms") format("woff"),
    url("../fonts/icomoon.svg?yemmms#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "bf-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-quote:before {
  content: "\ea40";
}
.icon-video:before {
  content: "\ea3f";
}
.icon-grapphic:before {
  content: "\ea3e";
}
.icon-education .path1:before {
  content: "\e915";
  color: rgb(241, 96, 81);
}
.icon-education .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-education .path4:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}
.icon-education .path5:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path6:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-education .path7:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path8:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path9:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path10:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path11:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path12:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-education .path13:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path14:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(79, 191, 159);
}
.icon-education .path15:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path16:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path17:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(46, 162, 219);
}
.icon-education .path18:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-education .path19:before {
  content: "\ea2c";
  margin-left: -1em;
  color: rgb(253, 191, 94);
}
.icon-education .path20:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-education .path21:before {
  content: "\ea38";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}
.icon-education .path22:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(207, 208, 209);
}
.icon-education .path23:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(207, 208, 209);
}
.icon-education .path24:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(207, 208, 209);
}
.icon-education .path25:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(207, 208, 209);
}
.icon-education .path26:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(207, 208, 209);
}
.icon-arrow-left-2:before {
  content: "\ea36";
}
.icon-arrow-right-2:before {
  content: "\ea37";
}
.icon-app .path1:before {
  content: "\e900";
  color: rgb(114, 242, 235);
}
.icon-app .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(20, 196, 187);
}
.icon-app .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(176, 209, 207);
}
.icon-app .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(20, 196, 187);
}
.icon-app .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(176, 209, 207);
}
.icon-app .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(249, 183, 103);
}
.icon-app .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(239, 157, 69);
}
.icon-app .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(80, 137, 221);
}
.icon-app .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(80, 137, 221);
}
.icon-app .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path12:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path13:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path14:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path15:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path16:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path17:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path18:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path19:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path20:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-app .path21:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-camera .path1:before {
  content: "\e916";
  color: rgb(242, 90, 120);
}
.icon-camera .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(204, 238, 255);
}
.icon-camera .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(204, 238, 255);
}
.icon-camera .path4:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(87, 85, 92);
}
.icon-camera .path5:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-camera .path6:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-camera .path7:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-camera .path8:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-camera .path9:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-camera .path10:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-camera .path11:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path1:before {
  content: "\e923";
  color: rgb(165, 221, 255);
}
.icon-contract-1 .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-contract-1 .path3:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(149, 229, 64);
}
.icon-contract-1 .path4:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(254, 102, 99);
}
.icon-contract-1 .path5:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path6:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path7:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path8:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path9:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path10:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path11:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path12:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path13:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-contract-1 .path14:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path1:before {
  content: "\e931";
  color: rgb(255, 255, 255);
}
.icon-curriculum .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 218, 160);
}
.icon-curriculum .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 188, 83);
}
.icon-curriculum .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path5:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path6:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path7:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path8:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path9:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path10:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path11:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path12:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-curriculum .path13:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path1:before {
  content: "\e93f";
  color: rgb(209, 229, 241);
}
.icon-design .path2:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(77, 186, 248);
}
.icon-design .path3:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(250, 84, 25);
}
.icon-design .path4:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path5:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(241, 217, 208);
}
.icon-design .path6:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path7:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path8:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path9:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(241, 217, 208);
}
.icon-design .path10:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path11:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path12:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path13:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path14:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path15:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path16:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path17:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path18:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path19:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path20:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path21:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path22:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path23:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path24:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path25:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path26:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path27:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path28:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-design .path29:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-font .path1:before {
  content: "\e95e";
  color: rgb(170, 193, 206);
}
.icon-font .path2:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 153, 51);
}
.icon-font .path3:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(37, 182, 210);
}
.icon-font .path4:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(170, 193, 206);
}
.icon-font .path5:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-font .path6:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(254, 88, 93);
}
.icon-idea .path1:before {
  content: "\e964";
  color: rgb(39, 59, 122);
}
.icon-idea .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(18, 17, 73);
}
.icon-idea .path3:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.icon-idea .path4:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(234, 162, 47);
}
.icon-idea .path5:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(208, 209, 211);
}
.icon-idea .path6:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(166, 168, 170);
}
.icon-idea .path7:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(254, 225, 135);
}
.icon-idea .path8:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.icon-idea .path9:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.icon-idea .path10:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(254, 225, 135);
}
.icon-idea .path11:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.icon-idea .path12:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(254, 225, 135);
}
.icon-idea .path13:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(255, 198, 27);
}
.icon-idea .path14:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(254, 225, 135);
}
.icon-idea .path15:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(255, 84, 25);
}
.icon-idea .path16:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(201, 47, 0);
}
.icon-idea .path17:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(201, 47, 0);
}
.icon-java .path1:before {
  content: "\e975";
  color: rgb(219, 56, 14);
}
.icon-java .path2:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(219, 56, 14);
}
.icon-java .path3:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(115, 161, 251);
}
.icon-java .path4:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(115, 161, 251);
}
.icon-java .path5:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(115, 161, 251);
}
.icon-java .path6:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(115, 161, 251);
}
.icon-java .path7:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(115, 161, 251);
}
.icon-java .path8:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(115, 161, 251);
}
.icon-layer .path1:before {
  content: "\e97d";
  color: rgb(20, 196, 187);
}
.icon-layer .path2:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(0, 160, 148);
}
.icon-layer .path3:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(249, 105, 130);
}
.icon-layer .path4:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(237, 53, 101);
}
.icon-layer .path5:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(80, 137, 221);
}
.icon-layer .path6:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(220, 249, 247);
}
.icon-layer .path7:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(220, 249, 247);
}
.icon-layer .path8:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(220, 249, 247);
}
.icon-online-learning .path1:before {
  content: "\e986";
  color: rgb(46, 162, 219);
}
.icon-online-learning .path2:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path3:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-online-learning .path4:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-online-learning .path5:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path6:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path7:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path8:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path9:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path10:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path11:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path12:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}
.icon-online-learning .path13:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path14:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-online-learning .path15:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(253, 191, 94);
}
.icon-online-learning .path16:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path17:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path18:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path19:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path20:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path21:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path22:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path23:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path24:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(241, 96, 81);
}
.icon-online-learning .path25:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-online-learning .path26:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path27:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-online-learning .path28:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-paper .path1:before {
  content: "\e9a2";
  color: rgb(22, 137, 252);
}
.icon-paper .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(164, 217, 245);
}
.icon-paper .path3:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(254, 196, 120);
}
.icon-paper .path4:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(22, 137, 252);
}
.icon-paper .path5:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(22, 137, 252);
}
.icon-paper .path6:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(22, 137, 252);
}
.icon-paper .path7:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(22, 137, 252);
}
.icon-paper .path8:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(22, 137, 252);
}
.icon-paper .path9:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(253, 0, 58);
}
.icon-portfolio-1 .path1:before {
  content: "\e9ac";
  color: rgb(255, 200, 112);
}
.icon-portfolio-1 .path2:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 218, 160);
}
.icon-portfolio-1 .path3:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-portfolio-1 .path4:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(186, 238, 131);
}
.icon-portfolio-1 .path5:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path6:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path7:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path8:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path9:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path10:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path11:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path12:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path13:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path14:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path15:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path16:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-1 .path17:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path1:before {
  content: "\e9be";
  color: rgb(255, 255, 255);
}
.icon-portfolio-3 .path2:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(254, 102, 99);
}
.icon-portfolio-3 .path3:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(255, 205, 172);
}
.icon-portfolio-3 .path4:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(165, 221, 255);
}
.icon-portfolio-3 .path5:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(255, 188, 83);
}
.icon-portfolio-3 .path6:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(254, 102, 99);
}
.icon-portfolio-3 .path7:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(154, 216, 255);
}
.icon-portfolio-3 .path8:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path9:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path10:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path11:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path12:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path13:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path14:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path15:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path16:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-portfolio-3 .path17:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path1:before {
  content: "\e9cf";
  color: rgb(255, 255, 255);
}
.icon-progress .path2:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(254, 210, 132);
}
.icon-progress .path3:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(255, 220, 160);
}
.icon-progress .path4:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(150, 237, 231);
}
.icon-progress .path5:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(193, 238, 146);
}
.icon-progress .path6:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(255, 93, 93);
}
.icon-progress .path7:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(254, 201, 107);
}
.icon-progress .path8:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(150, 237, 231);
}
.icon-progress .path9:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path10:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path11:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path12:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path13:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path14:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path15:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-progress .path16:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path1:before {
  content: "\e9e0";
  color: rgb(21, 102, 192);
}
.icon-question .path2:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(34, 150, 243);
}
.icon-question .path3:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-question .path4:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(197, 40, 40);
}
.icon-question .path5:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(244, 67, 53);
}
.icon-question .path6:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(255, 235, 58);
}
.icon-question .path7:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path8:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path9:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path10:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path11:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path12:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path13:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path14:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path15:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path16:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path17:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-question .path18:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-teacher .path1:before {
  content: "\e9f3";
  color: rgb(25, 54, 81);
}
.icon-teacher .path2:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}
.icon-teacher .path3:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path4:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path5:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(79, 191, 159);
}
.icon-teacher .path6:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path7:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path8:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path9:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path10:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path11:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path12:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path13:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path14:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(253, 191, 94);
}
.icon-teacher .path15:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(241, 96, 81);
}
.icon-teacher .path16:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path17:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path18:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path19:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(253, 191, 94);
}
.icon-teacher .path20:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path21:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path22:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path23:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(207, 208, 209);
}
.icon-teacher .path24:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path25:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path26:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path27:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path28:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path29:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path30:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path31:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path32:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path33:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path34:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path35:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path36:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path37:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path38:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path39:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path40:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path41:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(46, 162, 219);
}
.icon-teacher .path42:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path43:before {
  content: "\ea1d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path44:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path45:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(241, 96, 81);
}
.icon-teacher .path46:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path47:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path48:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-teacher .path49:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-teacher .path50:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(25, 54, 81);
}
.icon-list:before {
  content: "\ea2e";
}
.icon-grid:before {
  content: "\ea2f";
}
.icon-arrow-right:before {
  content: "\ea30";
}
.icon-key:before {
  content: "\ea31";
}
.icon-people:before {
  content: "\ea32";
}
.icon-book:before {
  content: "\ea33";
}
.icon-cart:before {
  content: "\ea34";
}
.icon-search:before {
  content: "\ea35";
}
