.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  &:hover {
    background-color: #fff;
  }
}

.my-float {
  margin-top: 16px;
  color: #fff;

  &:hover {
    color: #25d366;
  }
}
