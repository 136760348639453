.slider {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;

  & a {
    &.previousButton,
    &.nextButton {
      font-size: 22px;
      line-height: 0;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s linear;
      z-index: 1;
      padding: 10px;
      text-decoration: none;
      backface-visibility: hidden; /* prevent jump effect when scaling */

      &:not(.disabled):hover {
        transform: translateY(-50%) scale(1.25);
        cursor: pointer;
      }

      & svg {
        & polygon {
          fill: #cb824d;
        }
      }
    }

    &.previousButton {
      left: 20px;
    }

    &.nextButton {
      right: 20px;
    }
  }
}

.sliderContent {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  text-align: center;
  background-size: cover !important;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    // background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
  }

  &.hidden {
    visibility: hidden;
  }

  &.previous {
    left: -100%;
  }

  &.current {
    left: 0;
  }

  &.next {
    left: 100%;
  }

  &.animateIn,
  &.animateOut {
    transition: all 2s ease;
  }

  &.animateIn {
    &.previous,
    &.next {
      left: 0;
      visibility: visible;

      & p {
        transition-delay: 1.1s;
      }

      & button {
        transition-delay: 1.3s;
      }

      & section img {
        transition-delay: 1.3s;
      }

      & section span {
        transition-delay: 1.4s;
      }

      & section span strong {
        transition-delay: 1.5s;
      }
    }
  }

  &.animateOut {
    &.previous {
      left: 100%;
    }

    &.next {
      left: -100%;
    }

    & h1 {
      transition-delay: 0.3s;
    }

    & p {
      transition-delay: 0.2s;
    }

    & section span {
      transition-delay: 0.1s;
    }

    & section span strong {
      transition-delay: 0s;
    }
  }

  &.current,
  &.animateIn {
    & h1,
    & button,
    & p,
    & section * {
      transform: translateX(0);
      transition-delay: 0.9s;
      opacity: 1;
    }

    & button {
      &:hover {
        transition-delay: 0s;
      }
    }
  }

  & .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  & h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #ffffff;
    font-size: 64px;
    line-height: 1;
    transition: all 0.3s ease;
    transform: translateY(-20px);
    opacity: 0;
  }

  & p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
    transition: all 0.3s ease;
    transform: translateY(20px);
    opacity: 0;
  }

  & button {
    transition: all 0.3s ease;
    transform: translateY(20px);
    opacity: 0;
    background-color: #cb824d;
    padding: 0px 21px;
    &:hover {
      background-color: #000000;
    }
  }

  & section {
    position: absolute;
    bottom: 20px;
    left: 20px;

    & * {
      transition: all 0.3s ease;
    }

    & span {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      display: inline-block;
      text-align: left;
      line-height: 1.4;
      vertical-align: middle;
      margin-left: 10px;
      transform: translateX(-10px);
      opacity: 0;

      & strong {
        color: #ffffff;
        font-size: 14px;
        display: block;
        transform: translateY(10px);
        opacity: 0;
      }
    }

    & img {
      width: 40px;
      height: 40px;
      border: solid 2px rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      vertical-align: middle;
      transition: all 0.5s ease;
      transform: translateX(-20px);
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 550px) {
  .sliderContent {
    .inner {
      h1 {
        // font-weight: 900;
        // margin: 0 auto;
        // max-width: 840px;
        // color: #ffffff;
        // font-size: 64px;
        // line-height: 1;
        // transition: all 0.3s ease;
        // transform: translateY(-20px);
        // opacity: 0;
        font-size: 45px;
      }
    }
  }
}
