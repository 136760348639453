/**
  * 
  * Overlay
  * Flat Button
  * Flat Title
  * Parallax
  * Border
  * Flat Info
  * Flat Action
  * Flat Adds
  * Flat Event
  * Flat Featured
  * Iconbox
  * Imgbox
  * Flat Counter
  * Flat Tabs
  * Flat Team
  * Flat Search
  * Flat Accodion
  * Transition Hover
  * Flat Img Single Slider
  * Flat About Info
  * Flat About Detail
  * Flat Video
  * Flat Free Month
  * Flat Courses
  * Flat Filter Isotype
  * Flat Popular Courses
  * Flat Register Now
  * Flat Skilled Instructors
  * Flat Upcoming Events
  * Flat Testimonials
  * Flat Services
  * Flat Categories
  * Flat Post
  * Flat Comments
  * Flat Form Reply
  *
**/

/* Overlay
-------------------------------------------------- */
.overlay {
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.top .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #cb824d;
}

.top .overlay.v2 {
  background: #1d2f40;
}

.top .overlay.style2 {
  background: #f3728b;
}

.flat-title-page .overlay {
  background: #192836;
  opacity: 0.71;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.flat-title-page.style2 .overlay {
  opacity: 0.77;
}

.flat-title-page .overlay.style2 {
  background: #f3728b;
}

.flat-title-page .overlay.style3 {
  background: #cb824d;
}

.flat-title-page .overlay.style4 {
  background: #192836;
}

.flat-feature .overlay {
  background: #f3728b;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.flat-feature .overlay.v2 {
  opacity: 1;
}

.flat-feature.style2 .overlay {
  background: #fff;
  opacity: 0.75;
}

.product-item .overlay {
  background: #515e6f;
  position: absolute;
}

.flat-free-month .overlay {
  background: #ec5252;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.flat-register-now .overlay {
  background: #192836;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.88;
}

/* Flat Button
-------------------------------------------------- */
.flat-button {
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.flat-button::after,
.flat-button::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.flat-button::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: #cb824d;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.flat-button:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.flat-button.border {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  border-radius: 23px;
  padding: 17px 30px 15px 30px;
}

.flat-button.border::after {
  content: none;
}

/* Flat Title
-------------------------------------------------- */
.flat-title .title {
  margin-bottom: 37px;
  font-family: "Poppins";
}

.flat-title .description {
  margin-bottom: 74px;
  font-size: 16px;
  padding: 0 10% 0 8.5%;
  line-height: 26px;
}

/* Parallax
-------------------------------------------------- */
.parallax {
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 0;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.parallax1 {
  /* background: url("../images/title-page/bg-slider.jpg") center center no-repeat; */
}

.parallax2 {
  /* background: url("../images/footer2.jpg") center center no-repeat; */
  background-size: cover;
}

.parallax2.style2 {
  /* background: url("../images/feature/bg-feature-1.png") center center no-repeat; */
  background-size: cover;
}

.parallax3 {
  /* background: url("../images/title-page/bg-slider-2.jpg") center center no-repeat; */
}

.parallax4 {
  /* background: url("../images/title-page/bg-slider-3.png") center center no-repeat; */
}

.parallax5 {
  /* background: url("../images/title-page/bg-slider-4.png") center center no-repeat; */
}

.parallax6 {
  /* background: url("../images/title-page/bg-slider-5.png") center center no-repeat; */
  background-size: cover;
}

.parallax7 {
  /* background: url("../images/title-page/bg-slider-6.png") center center no-repeat; */
}

.parallax8 {
  /* background: url("../images/title-page/bg-slider-7.png") center center no-repeat; */
  background-size: cover;
}

.parallax9 {
  /* background: url("../images/courses/bg-free-month.png") center center no-repeat; */
  background-size: cover;
}

.parallax10 {
  /* background: url("../images/title-page/bg-slider-8.png") center center no-repeat; */
  background-size: cover;
}

.parallax11 {
  /* background: url("../images/title-page/bg-slider-9.png") center center no-repeat; */
  background-size: cover;
}

.parallax12 {
  /* background: url("../images/title-page/bg-slider-10.png") center center no-repeat; */
  background-size: cover;
}

.parallax13.style2 {
  /* background: url("../images/bg-video.jpg") center center no-repeat; */
}

.parallax14 {
  /* background: url("../images/free-month/bg-free-month.png") center center no-repeat; */
  background-size: cover;
}

.parallax15 {
  /* background: url("../images/free-month/bg-free-month-2.png") center center no-repeat; */
  background-size: cover;
}

.parallax16 {
  /* background: url("../images/calculadora.jpg") center center no-repeat; */
  background-size: cover;
}

.parallax17 {
  /* background: url("../images/contacto.jpg") center center no-repeat; */
  background-size: cover;
}

.parallax18 {
  /* background: url("../images/cursos.jpg") center center no-repeat; */
  background-size: cover;
}

/* Border
-------------------------------------------------- */
.border-bt-d1d1ff {
  border-bottom: 1px solid #3a465a;
}

.border-ra4 {
  border-radius: 4px;
}

.border-f-e6f3ff {
  border: 1px solid #e6f3ff;
}

.border-bt-e6f3ff {
  border-bottom: 1px solid #e6f3ff;
}

.border-l-e6f3ff {
  border-left: 1px solid #e6f3ff;
}

.border-r-e6f3ff {
  border-right: 1px solid #e6f3ff;
}

.border-t-e6f3ff {
  border-top: 1px solid #e6f3ff;
}

.border-bt-f0f0f0 {
  border-bottom: 1px solid #f0f0f0;
}

/* Flat Info
-------------------------------------------------- */
.flat-info ul li {
  display: inline-block;
}

.flat-info ul li:not(:last-child) {
  margin-right: 20px;
}

.flat-info a {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.flat-info ul li:before {
  content: "";
  font-family: "FontAwesome";
  color: #fff;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flat-info ul li.phone:before {
  content: "\f098";
}

.flat-info ul li.mail:before {
  content: "\f0e0";
}

.top .flat-info {
  margin-top: 15px;
}

/* style2 */
.top .flat-info.style2 {
  margin-top: 5px;
  margin-bottom: 3px;
}

.style2.flat-info a,
.style2.flat-info li:before {
  color: #515e6f;
}

.style2.flat-info li:hover a,
.style2.flat-info li:hover:before {
  color: #f3728b;
}

.style2.flat-info.v2 li:hover a,
.style2.flat-info.v2 li:hover:before {
  color: #ec5252;
}

/* Flat Action
-------------------------------------------------- */
.flat-action ul li {
  display: inline-block;
}

.flat-action ul li:not(:last-child):after {
  content: "|";
  margin: 0 5px 0 9px;
  font-weight: 700;
}

.flat-action a {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

/* style2 */
.flat-action.style2 ul li:not(:last-child):after {
  content: unset;
}

.flat-action.style2 ul li {
  border: 1px solid rgba(209, 209, 255, 0.18);
  padding: 5px 14px 0 10px;
  margin: 0 -2px;
  border-bottom: 0;
}

.flat-action.style2 ul li a {
  color: #98aac2;
}

.flat-action.style2 ul li:before {
  content: "";
  padding-right: 3px;
  font-family: "Font Awesome 5 Free";
  color: #98aac2;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flat-action.style2 ul li:hover a,
.flat-action.style2 ul li:hover:before {
  color: #f3728b;
}

.flat-action.style2.v2 ul li:hover a,
.flat-action.style2.v2 ul li:hover:before {
  color: #ec5252;
}

.flat-action.style2 ul li.register:before {
  content: "\f044";
}

.flat-action.style2 ul li.user:before {
  content: "\f2bd";
}

/* Flat Adds
-------------------------------------------------- */
.flat-adds {
  color: #fff;
}

.flat-adds p:first-child {
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.1px;
}

.flat-adds span.underline-text {
  text-decoration: underline;
}

.flat-adds .datetime {
  display: inline;
  background: #fa778a;
  border-radius: 5px;
  padding: 4px 7px;
  font-size: 12px;
}

/* Flat Event
-------------------------------------------------- */
.meta-date {
  position: absolute;
  width: 105px;
  height: 87px;
  background: #f3728b;
  font-weight: 900;
  color: #fff;
  padding-top: 20px;
}

.meta-date .day {
  font-size: 30px;
}

.meta-date .month {
  font-size: 16px;
}

.event-img {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.event-img img,
.author img {
  width: 100%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

.meta-datetime li:not(:last-child):after {
  content: "|";
  font-weight: 700;
  padding-left: 5px;
  color: #f3728b;
}

.meta-datetime li a {
  color: #f3728b;
  font-weight: 700;
}

.meta-datetime li a:hover {
  color: #515e6f;
}

.author {
  padding-right: 80px;
}

.author .author-avata {
  float: left;
  margin-right: 15px;
}

.author .name {
  margin-bottom: 5px;
}

.author .name a {
  color: #f3728b;
}

.author .name a:hover {
  color: #515e6f;
}

.author .author-avata {
  overflow: hidden;
  border-radius: 4px;
}

.author:hover img {
  transform: scale(1.2);
}

.event:hover .event-img img {
  transform: scale(1.1);
}

.meta-list ul li {
  margin-right: 14px;
}

.meta-list ul li:before {
  content: "";
  font-family: "FontAwesome";
  font-size: 15px;
  margin-right: 5px;
}

.meta-list ul li.time:before {
  content: "\f017";
  color: #00da9a;
}

.meta-list ul li.address:before {
  content: "\f124";
  color: #ff527b;
}

.event .meta-list li:not(:last-child):after,
.meta-rate > li:not(:last-child):after {
  content: unset;
}

/* style1 */
.flat-events.style1 {
  margin-bottom: 77px;
}

.event.style1 {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 50px;
}

.event.style1 .event-content {
  padding: 56px 0 0 0;
}

.event.style1 .meta-datetime {
  margin-bottom: 1px;
}

.event.style1 .title {
  line-height: 40px;
  margin-bottom: 15px;
}

.event.style1 .title a {
  font-weight: 700;
  font-family: "Poppins";
}

.event.style1 .description {
  margin-bottom: 41px;
}

.event.style1 .meta-date {
  top: 0;
  left: 0;
}

/* style2 */
.flat-events.style2 {
  margin-bottom: 49px;
}

.event.style2 {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 28px;
}

.event.style2 .meta-date {
  bottom: 0;
  right: 0;
  width: 80px;
  height: 105px;
  padding-top: 30px;
}

.event.style2 .event-content {
  padding: 27px 29px 23px 29px;
  background: #fff;
}

.event.style2 .event-content p {
  text-align: justify;
}

.event.style2 .meta-date .month {
  font-size: 14px;
}

.event.style2 .title {
  margin-bottom: 13px;
}

/* style3 */
.event.style3 .event-img img {
  width: unset;
}

.event.style3 .event-img {
  width: 255px;
  height: 255px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  overflow: unset;
  margin-bottom: 58px;
}

.event.style3 .event-img:before {
  content: "";
  width: 293px;
  height: 293px;
  border-radius: 50%;
  border: 4px dotted rgba(81, 94, 111, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: example 1s linear infinite;
  animation: example 1s linear infinite;
}

@-webkit-keyframes example {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotateZ(90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotateZ(180deg);
  }
  75% {
    transform: translate(-50%, -50%) rotateZ(270deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes example {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotateZ(90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotateZ(180deg);
  }
  75% {
    transform: translate(-50%, -50%) rotateZ(270deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

.event.style3 .meta-list ul li {
  margin-right: unset;
}

.event.style3 .meta-list ul li:not(:last-child):after {
  content: "|";
  padding-left: 5px;
  color: #fff;
}

.event.style3 .meta-list ul li:before {
  color: #f3728b;
}

.event.style3 .meta-list ul li.address:before {
  content: "\f041 ";
  font-family: "FontAwesome";
}

.event.style3 .meta-list ul li a {
  color: #fff;
}

.event.style3 .title a {
  color: #fff;
}

.event.style3 .title a:hover,
.event.style3 .meta-list ul li a:hover {
  color: #f3728b;
}

.event.style3 .description {
  color: #fff;
  margin-bottom: 27px;
}

.event.style3 .title {
  margin-bottom: 25px;
}

.event.style3 .meta-date {
  width: 97px;
  height: 97px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  padding-top: 29px;
}

.event.style3 .meta-date:after {
  content: "";
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.event.style3 .meta-date.bg-0046d4 {
  background: #0046d4;
}

.event.style3 .meta-date.bg-ffa81e {
  background: #ffa81e;
}

.event.style3 .meta-date.bg-f64260 {
  background: #f64260;
}

.event.style3 .bg-0046d4.meta-date:after {
  border: 5px solid rgba(0, 70, 212, 0.77);
}

.event.style3 .bg-ffa81e.meta-date:after {
  border: 5px solid rgba(255, 168, 30, 0.77);
}

.event.style3 .bg-f64260.meta-date:after {
  border: 5px solid rgba(246, 66, 96, 0.77);
}

.event.style3 .meta-date .day {
  font-family: "Noto Serif";
  font-weight: 700;
  font-style: italic;
  font-size: 40px;
  margin-bottom: 12px;
}

/* Flat Featured
-------------------------------------------------- */
.flat-feature {
  position: relative;
  padding: 52px 0 56px;
}

/* style2 */
.flat-featured.style2 {
  /* background: url("../images/courses/bg-feature-big.png") center center no-repeat; */
  background-size: cover;
  padding-top: 206px;
  padding-bottom: 236px;
}

.textbox {
  padding-left: 40.5%;
}

.textbox h3.title {
  color: #fff;
  line-height: 40px;
  font-family: "Poppins";
  margin-bottom: 36px;
}

.textbox .text {
  color: #fff;
  margin-bottom: 24px;
}

.textbox h6.title {
  color: #fff;
  line-height: 22px;
  font-family: "Poppins";
  margin-bottom: 15px;
}

.textbox .list-skill {
  margin-top: 38px;
  margin-bottom: 32px;
}

.textbox .list-skill li {
  color: #fff;
  margin-bottom: 7px;
}

.textbox .list-skill li:before {
  content: "\f192";
  font-family: "Font Awesome 5 Free";
  padding-right: 5px;
}

.textbox .btn-learning {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.textbox .btn-learning:after {
  content: " \f14d";
  font-family: "FontAwesome";
}

.textbox .btn-learning:hover:after {
  color: #f3728b;
}

.wrap-course {
  position: relative;
  margin-top: 18px;
  margin-left: 108px;
  margin-right: 129px;
  padding-bottom: 32px;
  /* background: url(../images/courses/bg-feature.png) center center no-repeat; */
  background-size: cover;
}

.wrap-course .flat-button.btn-buy.v2 {
  margin-left: 0;
}

.wrap-course .wrap-text {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 72px 0 0 0;
}

.level {
  font-size: 14px;
  letter-spacing: 0.22em;
  background: #ffc27a;
  color: #192836;
  line-height: 46px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  padding: 0 10px 0 15px;
}

.level:after {
  content: "";
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #ffc27a;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.level:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ffffff;
  opacity: 0.28;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.wrap-course .over {
  font-size: 66px;
  color: #fff;
  margin-bottom: 24px;
  padding-left: 65px;
}

.wrap-course .wrap-over {
  margin-bottom: 48px;
}

.wrap-course .count {
  font-size: 50px;
  color: #192836;
  position: relative;
}

.wrap-course .count:after {
  content: "";
  background: #192836;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.wrap-course .free {
  color: #fff;
  font-size: 26px;
  font-weight: 300;
}

.wrap-course .end {
  font-size: 12px;
  background: #ffffff;
  line-height: 21px;
  border-radius: 4px;
  padding: 3px 8px;
}

.wrap-course .wrap-time-view {
  margin-top: 12px;
  margin-bottom: 3px;
}

.wrap-course .wrap-time-view li {
  color: #fff;
}

.wrap-course .wrap-time-view li:before {
  content: "";
  font-family: "FontAwesome";
  padding-right: 5px;
}

.wrap-course .wrap-time-view li.time:before {
  content: "\f017 ";
  color: #00da9a;
}

.wrap-course .wrap-time-view li.view:before {
  content: "\f06e ";
  color: #ff527b;
}

.wrap-course .title a {
  color: #fff;
  font-family: "Poppins";
}

.wrap-course .title a:hover {
  color: #192836;
}

.wrap-course .price-wrap .sale-price {
  font-size: 40px;
}

.wrap-course .price-wrap .price {
  font-size: 25px;
}

.wrap-course .title {
  margin-bottom: 28px;
}

.flat-featured.style2 .price-wrap {
  margin-bottom: 25px;
}

.flat-featured.style2 .wrap-course .wrap-text {
  padding: 72px 0 0 35px;
}

.wrap-course .btn-buy {
  margin-right: 11px;
}

/* style3 */
.flat-featured.style3 .wrap-featured {
  position: absolute;
  width: 100%;
  transform: translateY(-100%);
}

/* style4 */
.flat-feature.style4 .overlay {
  background: #ec5252;
  opacity: 1;
}

.flat-feature.style4 .iconbox .iconbox-icon {
  color: #ec5252;
}

.flat-feature.style4 .iconbox .iconbox-icon:after {
  background: #fff;
  z-index: -1;
}

/* Iconbox
-------------------------------------------------- */
.iconbox {
  position: relative;
}

.iconbox .iconbox-icon {
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  position: absolute;
  color: #fff;
  font-size: 24px;
  left: 0;
  top: 50%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  transform: translateY(-50%);
  -webkit-animation: showhide 2s linear infinite;
  animation: showhide 2s linear infinite;
}

@-webkit-keyframes showhide {
  0% {
    border: 1px dotted rgba(255, 255, 255, 1);
  }
  50% {
    border: 1px dotted rgba(255, 255, 255, 0.5);
  }
  100% {
    border: 1px dotted rgba(255, 255, 255, 0);
  }
}

@keyframes showhide {
  0% {
    border: 1px dotted rgba(255, 255, 255, 1);
  }
  50% {
    border: 1px dotted rgba(255, 255, 255, 0.5);
  }
  100% {
    border: 1px dotted rgba(255, 255, 255, 0);
  }
}

.iconbox .iconbox-icon:after {
  content: "";
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  line-height: 56px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconbox .iconbox-content {
  padding: 0 15px;
}

.iconbox .iconbox-content .title {
  color: #fff;
  padding-left: 51px;
  font-weight: 700;
  font-family: "Poppins";
}

.flat-feature.style2 .iconbox .iconbox-content .title {
  color: #192836;
}

.flat-feature.style2 .iconbox .iconbox-icon {
  color: #ac9a26;
}

/* style3 */
.iconbox.style3 .iconbox-icon,
.iconbox.style4 .iconbox-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  position: unset;
  transform: unset;
  border-radius: unset;
  border: unset;
  font-size: 60px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-animation: unset;
  animation: unset;
}

.iconbox.style3.left .iconbox-icon {
  transform: rotate(-20deg);
}

.iconbox.style3.right .iconbox-icon,
.iconbox.style4.right .iconbox-icon {
  transform: rotate(15deg);
}

.iconbox.style3 .iconbox-icon:before,
.iconbox.style3 .iconbox-icon:after,
.iconbox.style4 .iconbox-icon:before,
.iconbox.style4 .iconbox-icon:after {
  content: unset;
}

.iconbox.style3 {
  background: rgba(255, 255, 255, 0.8);
  padding: 41px 30px 53px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.iconbox.style3 .iconbox-content .title,
.iconbox.style4 .iconbox-content .title {
  padding-left: 0;
}

.iconbox.style3 .iconbox-content .title a,
.iconbox.style4 .iconbox-content .title a {
  font-family: "Roboto";
}

.iconbox:hover.style3,
.iconbox.active.style3 {
  background: #f3728b;
}

.iconbox:hover.style3 .title a,
.iconbox.active.style3 .title a {
  color: #fff;
}

.iconbox.style3 .title a:hover,
.iconbox.style3.active .title a:hover {
  color: #192836;
}

.iconbox:hover.style3 .iconbox-icon,
.iconbox.active.style3 .iconbox-icon {
  margin-bottom: 30px;
}

.iconbox:hover.style3,
.iconbox.active.style3 {
  padding-top: 31px;
}

.iconbox.style4 .iconbox-icon {
  margin-bottom: 22px;
}

.iconbox.style4.two .iconbox-icon {
  transform: rotate(34deg);
}

.iconbox.style4.three .iconbox-icon {
  transform: rotate(-13deg);
}

.iconbox.style4.four .iconbox-icon {
  transform: rotate(17deg);
}

/* Imgbox
-------------------------------------------------- */
.imgbox {
  margin-bottom: 80px;
}

.imgbox-content.left {
  padding: 35px 77px 0 0;
}

.imgbox-content.right {
  padding: 48px 46px 0 34px;
}

.imgbox-content .title a {
  font-family: "Poppins";
}

.imgbox-content .title {
  margin-bottom: 25px;
}

.imgbox-content .text {
  margin-bottom: 25px;
}

.imgbox.style1 {
  margin: 1px;
}

/* Flat Counter
-------------------------------------------------- */
.counter {
  overflow: hidden;
  padding: 0 2.5px;
  border-radius: 6px;
}

.counter .numb {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  background: #fff;
  padding-top: 16px;
  padding-bottom: 10px;
}

.counter .text {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 23px;
  color: #fff;
}

.counter.days .text {
  background: #f3728b;
}

.counter.hours .text {
  background: #413dff;
}

.counter.minutes .text {
  background: #6a5bff;
}

.counter.seconds .text {
  background: #00e6c3;
}

/* style2 */
.flat-counter.style2 .wrap-counter {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
}

.counters.style2 {
  background: #fff;
  -webkit-box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0.25);
  -moz-box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0.25);
  box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0.25);
}

.counter.style2 .overlay {
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.counter.style2 .overlay .img-hover-1 {
  position: absolute;
  top: 8px;
  left: 11px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.counter.style2 .overlay .img-hover-2 {
  height: 100%;
  position: absolute;
  top: 0;
  right: -100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.counter:hover.style2 .overlay .img-hover-1,
.counter.active.style2 .overlay .img-hover-1 {
  opacity: 1;
}

.counter:hover.style2 .overlay .img-hover-2,
.counter.active.style2 .overlay .img-hover-2 {
  right: 0;
}

.counter.style2 {
  padding: 60px 0 67px 57px;
  position: relative;
}

.counter.style2 .icon {
  width: 80px;
  height: 80px;
  font-size: 80px;
  float: left;
  margin-right: 22px;
  position: relative;
  z-index: 1;
}

.counter.style2 .content {
  position: relative;
  z-index: 1;
}

.counter.style2 .numb {
  font-size: 30px;
  margin: 11px 0 6px 0;
  padding: 0;
  background: unset;
}

.counter.style2 .title {
  font-size: 16px;
}

/* Flat Tabs
-------------------------------------------------- */
/* style1 */
.tab-title li span {
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style1 li.active span,
.tab-title.style1 li:hover span {
  color: #fff;
}

.tab-title li {
  display: inline-block;
  line-height: 44px;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style1 li {
  border-radius: 4px;
}

.tab-title.style1 li.active,
.tab-title.style1 li:hover {
  background: #cb824d;
}

.tab-title.style1 li:after {
  opacity: 0;
  content: "";
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #cb824d;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -9px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style1 li.active:after,
.tab-title.style1 li:hover:after {
  opacity: 1;
}

.tab-title.style1 li span:before {
  content: "";
  font-family: "FontAwesome";
  margin-right: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style1 li.map span:before {
  content: "\f278";
  color: #f3728b;
}

.tab-title.style1 li.event span:before {
  content: "\f022";
  color: #2ebd59;
  font-family: "Font Awesome 5 Free";
}

.tab-title.style1 li.overview span:before {
  content: "\f044";
  color: #7bdeff;
}

.tab-title.style1 li.review span:before {
  content: "\f006";
  color: #f4c561;
}

.tab-title.style1 li.requirements span:before {
  content: "\f115";
  color: #f3728b;
}

.tab-title.style1 li.active span:before,
.tab-title.style1 li:hover span:before {
  color: #fff;
}

/* style2 */
.tab-title.style2 li:not(:last-child) {
  border-right: 1px solid #e6f3ff;
}

.tab-title.style2 li.active,
.tab-title.style2 li:hover {
  color: #b2a23b;
}

.tab-title.style2 li {
  padding: 16px 30px 6px;
}

.tab-title.style2 li span {
  font-size: 22px;
}

.flat-tabs.style2 .item-content {
  padding: 80px 0 16px 0;
}

/* style3 */
.tab-title.style3 li {
  padding: 0 18px;
  line-height: 47px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style3 li:hover,
.tab-title.style3 li.active {
  border: 1px solid #e6f3ff;
  background: #f3728b;
  border-radius: 4px;
}

.tab-title.style3 li span {
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style3 li:hover span,
.tab-title.style3 li.active span {
  color: #fff;
}

.tab-title.style3 {
  margin-bottom: 80px;
}

/* style4 */
.tab-title.style4 {
  padding: 10px 0 7px;
  background: rgba(255, 225, 255, 0.1);
}

.tab-title.style4 li {
  color: #fff;
  padding: 0 24px 0 35px;
  font-size: 16px;
  line-height: 58px;
}

.tab-title.style4 li:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.14);
}

.tab-title.style4 li:hover,
.tab-title.style4 li.active {
  color: #f3728b;
}

/* style5 */
.tab-title.style5 li {
  padding: 0;
}

.tab-title.style5 li:after {
  content: "";
  width: 0%;
  height: 6px;
  background: #f3728b;
  position: absolute;
  bottom: -47px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tab-title.style5 li:hover:after,
.tab-title.style5 li.active:after {
  width: 100%;
}

.tab-title.style5 {
  padding-bottom: 40px;
  border-bottom: 6px solid rgba(81, 94, 111, 0.08);
}

.tab-title.style5 li:hover .iconbox.style4 .iconbox-content .title a,
.tab-title.style5 li.active .iconbox.style4 .iconbox-content .title a {
  color: #f3728b;
}

.tab-content.active {
  transform: translateY(0);
  visibility: visible;
}

.tab-content.active.box-shadow {
  -webkit-box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0.25);
  -moz-box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0.25);
  box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0.25);
}

.tab-content {
  visibility: hidden;
  transform: translateY(50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* Flat Team
-------------------------------------------------- */
.team-member {
  overflow: hidden;
  margin-bottom: 27px;
}

.team-member .avata-member {
  overflow: hidden;
}

.team-member .avata-member img {
  width: 100%;
}

.team-member:hover img {
  transform: scale(1.2);
}

.team-member .info-member {
  padding: 26px 29px 16px 29px;
  background: #fff;
}

.team-member .name {
  margin-bottom: 9px;
}

.team-member .position {
  margin-bottom: 10px;
}

.team-member .description {
  line-height: 22px;
  margin-bottom: 10px;
}

.team-member .socails {
  background: #efeff0;
}

.team-member .socails.no-bg {
  background: unset;
}

.team-member .socails li a {
  line-height: 49px;
  padding: 0 11px;
  display: block;
}

.team-member .socails li.facebook a {
  color: #3b5998;
}

.team-member .socails li.instagram a {
  color: #e4405f;
}

.team-member .socails li.skype a {
  color: #00aff0;
}

.team-member .socails li.github a {
  color: #211f1f;
}

/* Flat Search
-------------------------------------------------- */
.flat-search {
  width: 641px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.flat-search input[type="search"] {
  width: 100%;
  background-color: #eff0f1;
  font-size: 15px;
  font-weight: 400;
  height: 58px;
  padding-left: 30px;
}

.flat-search button.btn-search {
  width: 65px;
  height: 58px;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 17px;
  line-height: 58px;
  border: unset;
}

.flat-search button.flat-button.btn-search:before {
  background: #192836;
}

/* Flat Accodion
-------------------------------------------------- */
.accordion-toggle {
  background: #fff;
  margin-bottom: 9px;
  padding: 8px 25px 10px 28px;
}

.flat-faqs .heading .title-small {
  font-size: 14px;
  color: #f3728b;
  margin-bottom: 5px;
}

.flat-faqs .heading .title {
  color: #192836;
  margin-bottom: 15px;
}

.flat-faqs .heading {
  margin-bottom: 84px;
}

.flat-faqs .toggle-title {
  font-size: 22px;
  font-weight: 700;
  color: #192836;
  cursor: pointer;
  position: relative;
  padding: 15px 30px 15px 0;
}

.flat-faqs .toggle-title:after {
  content: "\ea37";
  font-family: "bf-icon";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: rgba(81, 94, 111, 0.15);
  background: #f5f5f6;
  border: 1px solid rgba(81, 94, 111, 0.15);
  border-radius: 50%;
  line-height: 38px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flat-faqs .toggle-content {
  padding: 16px 79px 0 0;
}

.flat-faqs .toggle-content p {
  margin-bottom: 22px;
}

.flat-faqs .toggle-content .title {
  margin-bottom: 26px;
}

.flat-faqs .toggle-content .title a {
  color: #f3728b;
}

.flat-faqs .active.toggle-title:after {
  transform: translateY(-50%) rotate(90deg);
  color: rgba(243, 114, 139, 0.15);
  border: 1px solid rgba(243, 114, 139, 0.15);
  background: #fdeaee;
}

/* Transition Hover
-------------------------------------------------- */
/* Transition Img Socails */
.transition-img-socails {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.transition-img {
  overflow: hidden;
  position: relative;
}

.transition-img img {
  width: 100%;
}

.transition-img-socails .overlay {
  top: 50%;
  left: 50%;
  height: 0%;
  width: 0%;
  transform: translate(-50%, -50%) rotate3d(1, 1, 1, -90deg);
  opacity: 0.8;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.transition-img-socails:hover .overlay {
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate3d(1, 1, 1, 0deg);
}

.transition-img-socails .socails {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) rotate3d(1, 1, 1, -180deg);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.transition-img-socails:hover .socails {
  opacity: 1;
  transform: translate(-50%, -50%) rotate3d(1, 1, 1, 0deg);
}

.transition-img-socails .socails ul li a {
  width: 48px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
}

.transition-img-socails .socails ul li a:hover {
  color: #f3728b;
  border: 1px solid #f3728b;
}

/* Transition Hover Plus */
.transition-plus {
  overflow: hidden;
}
.transition-plus .img-plus {
  position: relative;
  overflow: hidden;
}

.transition-plus .overlay {
  width: 100%;
  height: 100%;
  background: #515e6f;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.transition-plus:hover .overlay {
  opacity: 0.8;
}

.transition-plus .overlay:after,
.transition-plus .overlay:before {
  content: "";
  background-color: #f3728b;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.transition-plus .overlay:after {
  height: 30px;
  width: 1px;
  top: -30px;
  left: 50%;
}

.transition-plus:hover .overlay:after {
  top: 50%;
}

.transition-plus .overlay:before {
  width: 30px;
  height: 1px;
  top: 50%;
  left: -30px;
}

.transition-plus:hover .overlay:before {
  left: 50%;
}

/* Transition Hover Vline */
.vline {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
  width: -webkit-calc(50% - 20px);
  width: -moz-calc(50% - 20px);
  width: calc(50% - 20px);
  height: -webkit-calc(50% - 20px);
  height: -moz-calc(50% - 20px);
  height: calc(50% - 20px);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.vline-bottom {
  top: auto;
  left: auto;
  bottom: 20px;
  right: 20px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.widget-recent-work .vline {
  top: 5px;
  left: 5px;
  width: -webkit-calc(50% - 5px);
  width: -moz-calc(50% - 5px);
  width: calc(50% - 5px);
  height: -webkit-calc(50% - 5px);
  height: -moz-calc(50% - 5px);
  height: calc(50% - 5px);
}

.widget-recent-work .vline-bottom {
  bottom: 5px;
  right: 5px;
  top: auto;
  left: auto;
}

.vline:after,
.vline:before {
  content: "";
  background: #fff;
  display: block;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.img-vline {
  position: relative;
}

.img-vline .overlay {
  background: #515e6f;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.transition-vline:hover .img-vline .overlay {
  opacity: 0.5;
}

.transition-vline:hover .vline:before {
  width: 100%;
}

.transition-vline .vline:before {
  height: 1px;
  width: 0;
}

.transition-vline:hover .vline:after {
  height: 100%;
}
.transition-vline .vline:after {
  width: 1px;
  height: 0;
}

/* Flat Img Single Slider
-------------------------------------------------- */
.flat-img-single-slider .owl-carousel .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #fff !important;
  color: #fff !important;
  width: 48px;
  height: 48px;
  font-size: 30px !important;
  line-height: 46px !important;
}

.flat-img-single-slider .owl-carousel .owl-nav button:hover {
  color: #f3728b !important;
  border: 1px solid #f3728b !important;
}

.flat-img-single-slider .owl-carousel .owl-nav button span {
  font-size: 51px;
  font-weight: 100;
  line-height: 40px;
}

.flat-img-single-slider .owl-carousel .owl-nav button.owl-next {
  right: 27px;
}

.flat-img-single-slider .owl-carousel .owl-nav button.owl-prev {
  left: 27px;
}

/* Flat About Info
-------------------------------------------------- */
.flat-about-info {
  position: relative;
}

.info-img {
  width: 57.21%;
}

.info-content {
  padding: 33px 48px 35px 40px;
  width: 48.65%;
  background: #fff;
  position: absolute;
  top: 75px;
  right: 0;
}

.info-content .heading .title-small {
  color: #f3728b;
  font-size: 14px;
  margin-bottom: 7px;
}

.info-content .heading .title a {
  font-family: "Poppins";
}

.info-content .heading .title {
  margin-bottom: 20px;
}

.info-content .text {
  margin-bottom: 25px;
}

.info-content .text.font-Publico {
  font-size: 20px;
  font-weight: 400;
}

.info-content .wrap-btn {
  margin-top: 33px;
}

.flat-button.btn-contact {
  padding: 0 27px;
  letter-spacing: 0.01em;
  display: inline-block;
  line-height: 46px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins";
  border-radius: 4px;
  background: #00bb2d;
  color: #fff;
}

.flat-button.btn-contact:hover {
  background: transparent;
  color: #f3728b;
}

.flat-button.btn-contact::before {
  background: #192836;
}

/* Flat About Detail
-------------------------------------------------- */
.flat-about-detail .heading .title {
  font-size: 40px;
  font-weight: 300;
  line-height: 56px;
  padding: 0 7%;
  text-align: center;
  margin-bottom: 93px;
}

/* Flat Video
-------------------------------------------------- */
.flat-video {
  padding: 196px 0 243px;
  /* background: url("../images/servicios.jpg") center center no-repeat; */
  background-size: cover;
  overflow: hidden;
}

.flat-video .title a {
  font-family: "Poppins";
}

.flat-video .title {
  margin-bottom: 41px;
}

.flat-video .description,
.flat-video .link-site {
  margin-bottom: 1px;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
}

.flat-video .description {
  padding-left: 120px;
}

.flat-video .link-site {
  color: #f3728b;
}

.flat-video .video {
  position: relative;
}

.flat-video .video-icon span {
  font-size: 88px;
  position: relative;
}

.flat-video .video-icon a {
  color: #f3728b;
}

.flat-video .video-icon a:hover {
  color: #192836;
}

.flat-video .video-icon {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 88px;
  height: 88px;
}

.videobox-animation {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border-width: 0.5px;
  border-style: solid;
  opacity: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.videobox-link .videobox-animation {
  -webkit-animation: pulse-circle 3.9s linear 0s infinite;
  -moz-animation: pulse-circle 3.9s linear 0s infinite;
  -o-animation: pulse-circle 3.9s linear 0s infinite;
  animation: pulse-circle 3.9s linear 0s infinite;
}

.videobox-link .videobox-animation.circle-2 {
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.videobox-link .videobox-animation.circle-3 {
  -webkit-animation-delay: 2.6s;
  -moz-animation-delay: 2.6s;
  -o-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

.videobox-link:hover .videobox-animation {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

@-webkit-keyframes pulse-circle {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1.44);
    transform: scale(1.44);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(3.2);
    transform: scale(3.2);
    opacity: 0;
  }
}

@-moz-keyframes pulse-circle {
  0% {
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  20% {
    -moz-transform: scale(1.44);
    transform: scale(1.44);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(3.2);
    transform: scale(3.2);
    opacity: 0;
  }
}

@-o-keyframes pulse-circle {
  0% {
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  20% {
    -o-transform: scale(1.44);
    transform: scale(1.44);
    opacity: 0.5;
  }
  100% {
    -o-transform: scale(3.2);
    transform: scale(3.2);
    opacity: 0;
  }
}

@keyframes pulse-circle {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1.44);
    -moz-transform: scale(1.44);
    -o-transform: scale(1.44);
    transform: scale(1.44);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(3.2);
    -moz-transform: scale(3.2);
    -o-transform: scale(3.2);
    transform: scale(3.2);
    opacity: 0;
  }
}

/* style2 */
.flat-video.style2 {
  position: relative;
  padding: 134px 0 175px 0;
}

.flat-video.style2 .overlay {
  background: #1b2d3d;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.flat-video.style2 .icon-video {
  position: relative;
}

.flat-video.style2 .icon {
  width: 88px;
  height: 88px;
  position: relative;
  margin: 0 auto;
}

.flat-video.style2 .content {
  margin-top: 42px;
}

.flat-video.style2 .icon a {
  font-size: 90px;
  color: #fff;
}

.flat-video.style2 .icon a:hover {
  color: #192836;
}

.flat-video.style2 .title a {
  color: #fff;
}

.flat-video.style2 .title {
  margin-bottom: 38px;
}

.flat-video.style2 .description {
  color: #fff;
  padding: 0 25%;
  font-size: 20px;
  line-height: 34px;
  font-family: "Noto Serif";
}

.flat-video.style2 .link-site {
  color: #fff;
  font-style: italic;
  text-decoration: underline;
}

/* Flat Free Month
-------------------------------------------------- */
.flat-free-month {
  position: relative;
  padding: 82px 0 86px 0;
}

.flat-free-month .service-wrap {
  position: relative;
}

.flat-free-month .title {
  margin-bottom: 20px;
}

.flat-free-month .title a {
  font-family: "Poppins";
  color: #fff;
}

.flat-free-month .description,
.flat-free-month .description a {
  color: #fff;
  line-height: 32px;
  font-size: 20px;
  font-family: "Noto Serif";
  font-weight: 700;
}

.flat-free-month .description {
  padding: 0 45px;
  margin-bottom: 57px;
}

.flat-free-month .description a {
  text-decoration: underline;
  font-style: italic;
}

/* style2 */
.flat-free-month.style2 {
  padding: 99px 0 55px 0;
}

.flat-free-month.style2 .title {
  margin-bottom: 18px;
}

.flat-free-month.style2 .title a {
  font-weight: 400;
  color: #192836;
}

.flat-free-month.style2 .title a span {
  color: #f3728b;
  font-weight: 700;
  font-style: italic;
}

.flat-free-month.style2 .description {
  color: #192836;
  padding: 0;
  font-size: 16px;
  line-height: 26px;
  font-family: "Roboto";
  font-weight: 500;
}

.flat-free-month.style2 .description .link-site {
  color: #f3728b;
}

.flat-free-month.style2 .description .link-site.v2 {
  text-decoration: unset;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}

.flat-free-month.style2 .description .v2.link-site:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0px;
  background: #f3728b;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flat-free-month.style2 .description .v2.link-site:hover:after {
  width: 0%;
}

.flat-free-month.style2 .wrap-btn {
  margin-top: 60px;
}

.flat-button.btn-start {
  font-size: 16px;
  display: inline-block;
  line-height: 49px;
  padding: 3px 56px 3px 15px;
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.flat-button.btn-start::before {
  background: #192836;
}

.flat-button.btn-start:after {
  content: "\ea37";
  font-family: "bf-icon";
  font-weight: 100;
  font-size: 14px;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
}

.flat-button.btn-start.v2 {
  background: #f3728b;
}

.flat-btn2.v2.btn-start:hover {
  background: rgba(255, 255, 255, 1);
  color: #f3728b;
}
.flat-btn2.btn-start.v3 {
  background: #536dfe;
  border: unset;
  padding: 0 55px 0 30px;
  line-height: 58px;
}

.flat-btn2.v3.btn-start:hover {
  background: rgba(255, 255, 255, 1);
  color: #536dfe;
}

.flat-btn2.btn-start.v4 {
  background: #ec5252;
}

.flat-btn2.v4.btn-start:hover {
  background: rgba(255, 255, 255, 1);
  color: #ec5252;
  border: 2px solid #ec5252;
}

/* Flat Courses
-------------------------------------------------- */
.course {
  overflow: hidden;
  padding: 0 15px;
  margin-bottom: 30px;
}

.course-img img {
  width: 100%;
}

.course-content {
  background: #fff;
}

.course-content .text-wrap {
  padding: 30px 30px 0 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.course-content .title {
  margin-bottom: 7px;
}

.course-content .title a {
  font-family: "Poppins";
}

.course-content .teacher {
  margin-bottom: 9px;
}

.course-content .description {
  margin-bottom: 23px;
}

.course-content .wrap-rating-price {
  padding: 12px 0 9px 31px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.course-content .price {
  position: absolute;
  top: 13px;
  right: 26px;
  font-weight: 700;
  line-height: 21px;
  font-size: 12px;
  padding: 0 8px 0 5px;
  background: rgba(46, 189, 89, 0.15);
  border-radius: 4px;
}

.course-content .star-list li {
  margin-left: -2px;
  font-size: 12px;
}

.course.text-2ebd59 .teacher a {
  color: #2ebd59;
}

.course.text-2ebd59 .price.v2 {
  background: rgba(46, 189, 89, 1);
}

/* .course.text-ea0042 .price {
  background: rgba(234, 0, 66, 0.15);
} */

.course.text-ea0042 .price.v2 {
  background: rgba(234, 0, 66, 1);
}

.course.text-ea0042 .teacher a {
  color: #2ebd59;
}

.course.text-c100ea .price {
  background: rgba(193, 0, 234, 0.15);
}

.course.text-c100ea .teacher a {
  color: #c100ea;
}

.course.text-256cff .price {
  background: rgba(37, 108, 255, 0.15);
}

.course.text-256cff .teacher a {
  color: #256cff;
}

.course.text-8828ff .price {
  background: rgba(136, 40, 255, 0.15);
}

.course.text-8828ff .teacher a {
  color: #8828ff;
}

.course.text-0dacff .price {
  background: rgba(13, 172, 255, 0.15);
}

.course.text-0dacff .teacher a {
  color: #0dacff;
}

.course.text-536dfe .price {
  background: rgba(83, 109, 254, 0.15);
}

.course.text-536dfe .teacher a {
  color: #536dfe;
}

.course.text-ffbf13 .price {
  background: rgba(255, 191, 19, 0.15);
}

.course.text-ffbf13 .teacher a {
  color: #ffbf13;
}

.course.text-ff562f .price {
  background: rgba(255, 86, 47, 0.15);
}

.course.text-ff562f .teacher a {
  color: #ff562f;
}

.course.text-ec5252 .price {
  background: rgba(236, 82, 82, 0.15);
}

.course.text-ec5252 .teacher a {
  color: #ec5252;
}

.course.text-3b42ff .price {
  background: rgba(59, 66, 255, 0.15);
}

.course.text-3b42ff .teacher a {
  color: #3b42ff;
}

.course.text-37adff .price {
  background: rgba(55, 173, 255, 0.15);
}

.course.text-37adff .teacher a {
  color: #37adff;
}

.course.text-1cd566 .price {
  background: rgba(28, 213, 102, 0.15);
}

.course.text-1cd566 .teacher a {
  color: #1cd566;
}

.course .price.v2 {
  color: #fff;
}

.course.text-005eea .price.v2 {
  background: rgba(0, 94, 234, 1);
}

.course.text-005eea .teacher a {
  color: #005eea;
}

.course.text-00dcea .price.v2 {
  background: rgba(0, 220, 234, 1);
}

.course.text-00dcea .teacher a {
  color: #00dcea;
}

.course.text-fb868f .price.v2 {
  background: rgba(251, 134, 143, 1);
}

.course.text-fb868f .teacher a {
  color: #fb868f;
}

/* style2 */
.flat-courses.style2 {
  margin: 0 -15px 21px;
}

.course.style2 .course-content .text-wrap {
  padding: 26px 3px 0 30px;
}

/* style3 */
.flat-courses.style3 {
  margin: 0 -15px 21px;
}

.course.style3 .course-content {
  position: relative;
}

.course.style3 .course-content .text-wrap {
  padding-top: 20px;
  padding-bottom: 21px;
}

.course.style3 .course-content .price {
  top: 23px;
  right: 30px;
}

.course.style3 .course-content .title {
  margin-bottom: 13px;
}

/* style4 */
.flat-courses.style4 {
  margin-bottom: 40px;
}

.course.style4 .course-img-box {
  width: 350px;
  float: left;
}

.course.style4 .course-content {
  float: left;
  width: -webkit-calc(100% - 350px);
  width: -moz-calc(100% - 350px);
  width: calc(100% - 350px);
}

.course.style4 {
  padding: 0;
  margin-bottom: 15px;
}

.course.style4 .star-list {
  margin-right: 5px;
}

.course.style4 .course-content .text-wrap {
  padding-bottom: 26px;
}

.course:hover.style4 .course-content .text-wrap {
  background: #f0f2ff;
}

.course:hover.style4 .course-content .wrap-rating-price {
  background: #e3e7ff;
}

/* style5 */
.course.style5.v1 {
  width: 66.67%;
}

.course.style5.v2,
.widget-course {
  width: 33.33%;
}

.course.style5 .course-content {
  position: relative;
}

.course.style5 .wrap-rating {
  position: absolute;
  top: 33px;
  right: 30px;
}

.course.style5.v2 .wrap-rating {
  top: 23px;
}

.course.style5 .wrap-rating span {
  font-size: 12px;
}

.btn-learn-more {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-learn-more:after {
  content: "\ea37";
  font-family: "bf-icon";
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: -19px;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-learn-more:hover:after {
  right: -27px;
}

.course.style5 .course-content .title a {
  font-family: "Roboto";
  line-height: 30px;
}

.course.style5.v1 .course-content .text-wrap {
  padding: 34px 30px 40px 37px;
}

.course.style5.v2 .course-content .text-wrap {
  padding: 24px 30px 20px 28px;
}

.course.style5.v1 .course-content .teacher {
  margin-bottom: 5px;
}

.course.style5.v2 .course-content .teacher {
  margin-bottom: 10px;
}

.course.style5 .course-content .description {
  line-height: 22px;
}

.course.style5.v1 .course-content .title {
  margin-bottom: 20px;
}

.course.style5.v2 .course-content .title {
  margin-bottom: 16px;
}

.course.style5 .widget-subscribe {
  margin-bottom: unset;
}

.course.style5.v2 .btn-learn-more {
  font-size: 14px;
}

.course.style5.v2 .btn-learn-more:after {
  font-size: 11px;
  right: -16px;
}

.course.style5.v2 .btn-learn-more:hover:after {
  right: -25px;
}

/* Flat Filter Isotype
-------------------------------------------------- */
.flat-filter-isotype {
  margin-bottom: 50px;
}

.flat-filter-isotype li {
  display: inline-block;
  margin: 0 6px;
  margin-bottom: 30px;
}

.flat-filter-isotype li a {
  line-height: 57px;
  background: #fff;
  border: 1px solid rgba(26, 34, 125, 0.22);
  font-weight: 700;
  display: block;
  padding: 0 34px 0 36px;
  border-radius: 4px;
}

.flat-filter-isotype li.active a,
.flat-filter-isotype li a:hover {
  background: #cb824d;
  color: #fff;
  border: 1px solid rgba(26, 34, 125, 0);
}

/* Flat Popular Courses
-------------------------------------------------- */
.flat-popular-courses {
  padding: 0 75px 100px;
}

.flat-popular-courses .owl-carousel .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #434e78 !important;
  color: #434e78 !important;
  width: 48px;
  height: 48px;
  font-size: 36px !important;
  line-height: 46px !important;
  text-align: center;
  font-weight: 900 !important;
}

.flat-popular-courses .owl-carousel .owl-nav button.owl-next {
  right: -47px;
}

.flat-popular-courses .owl-carousel .owl-nav button.owl-prev {
  left: -47px;
}

.flat-popular-courses .owl-carousel .owl-nav button:hover,
.flat-popular-courses .owl-carousel .owl-nav button.disabled {
  color: #f3728b !important;
  border: 1px solid #f3728b !important;
}

.flat-popular-courses .owl-carousel .owl-nav button span {
  font-size: 51px;
  font-weight: 100;
  line-height: 40px;
}

.flat-popular-courses .flat-tabs {
  margin-bottom: 19px;
}

.flat-button.btn-all-sourses {
  padding: 0 43px;
  line-height: 65px;
  letter-spacing: 0.01em;
  font-size: 16px;
  background: #f3728b;
  color: #fff;
}

.flat-button.btn-all-sourses.v2 {
  background-color: #ec5252;
}

.flat-button.btn-all-sourses:hover {
  background: transparent;
}

.flat-button.btn-all-sourses::before {
  background-color: #192836;
}

/* style2 */
.flat-popular-courses.style2 {
  padding: 95px 75px 49px;
}

.flat-popular-courses.style2 .course-content .title a {
  font-family: "Roboto";
}

.flat-popular-courses.style2 .flat-title .title {
  margin-bottom: 40px;
}

.flat-popular-courses.style2.v2 .flat-title .title {
  margin-bottom: 37px;
}

.flat-popular-courses.style2 .course.style5 .teacher a {
  color: #515e6f;
  font-weight: 700;
}

/* style3 */
.flat-popular-courses.style3 {
  padding: 195px 75px 52px;
}

.flat-popular-courses.style3 .course-content .title a {
  font-family: "Roboto";
}

/* style4 */
.flat-popular-courses.style4 {
  padding: 103px 75px 93px;
}

/* Flat Register Now
-------------------------------------------------- */
.flat-register-now {
  /* background: url("../images/register/bg-register.png") center center no-repeat; */
  position: relative;
  padding: 100px 0 130px;
}

.flat-register-now .tab-content.active {
  -webkit-box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0);
  -moz-box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0);
  box-shadow: 6px 18px 50px 13px rgba(157, 172, 193, 0);
}

.flat-register-now:after {
  content: "";
  /* background: url(../images/title-page/bg-white-2.png) center center no-repeat; */
  height: 38px;
  width: 1920px;
  position: absolute;
  bottom: 0;
}

.flat-register-now:before {
  content: "";
  border-top: 76px solid transparent;
  border-right: 1920px solid rgba(255, 255, 255, 0.54);
  position: absolute;
  bottom: 0;
  z-index: 99;
}

.flat-register-now .wrap-form {
  border-radius: 4px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
}

.flat-register-now .heading {
  padding-top: 44px;
  color: #fff;
  margin-bottom: 42px;
}

.flat-register-now form {
  padding: 0 36px 50px 38px;
}

.flat-register-now .heading .title {
  margin-bottom: 11px;
  color: #fff;
}

.flat-register-now .free {
  background: rgba(255, 255, 255, 0.29);
  color: #fff;
  line-height: 34px;
}

.flat-register-now .free a {
  font-style: italic;
  text-decoration: underline;
  font-weight: 700;
  color: #fff;
}

.flat-register-now input[type="text"],
.flat-register-now input[type="email"] {
  background: rgba(114, 125, 135, 0.3);
  font-size: 12px;
}

.flat-register-now .wrap-input {
  margin-bottom: 30px;
}

.flat-register-now .wrap-input.last-child {
  margin-bottom: 51px;
}

.flat-register-now button.flat-button.btn-apply {
  height: 47px;
  line-height: 44px;
  padding: 0 34px;
  font-weight: 700;
  font-size: 16px;
  border: unset;
}

.flat-register-now button.flat-button.btn-apply:before {
  background: #192836;
}

.flat-register-now .video {
  position: relative;
  float: right;
  margin-left: 15px;
}

.flat-register-now .video .icon-video {
  width: 53px;
  height: 53px;
  font-size: 53px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flat-register-now .video .icon-video:hover {
  color: #f3728b;
}

.flat-register-now .item-content {
  padding-top: 46px;
}

.flat-register-now .item-content .title a,
.flat-register-now .item-content .text {
  color: #fff;
}

.flat-register-now .item-content .title {
  margin-bottom: 19px;
}

.flat-register-now .item-content .title.mg-bottom-25 {
  margin-bottom: 25px;
}

.flat-register-now .item-content .text {
  font-size: 16px;
  margin-bottom: 16px;
}

/* Flat Skilled Instructors
-------------------------------------------------- */
.flat-skilled-instructors {
  padding: 80px 0 46px 0;
}

.flat-skilled-instructors.style2 {
  padding: 97px 0 69px 0;
}

.flat-skilled-instructors.style3 {
  padding: 63px 0 71px 0;
}

/* Flat Upcoming Events
-------------------------------------------------- */
.flat-upcoming-events {
  padding: 137px 0 27px 0;
  /* background: url("../images/events/bg-event-index.png") center center no-repeat; */
  background-size: cover;
}

.flat-upcoming-events.style1 .meta-list ul li:before {
  margin-right: 4px;
}

.flat-upcoming-events.style1 .meta-list ul li {
  margin-right: 15px;
}

.flat-upcoming-events.style1 .event.style2 .meta-date .month {
  font-size: 16px;
  font-weight: 900;
}

.flat-upcoming-events.style1 .meta-date .day {
  margin-bottom: 4px;
}

.flat-upcoming-events .event.style2 .event-content {
  background-color: #fff;
}

.flat-upcoming-events .event.style2 .meta-date.bg-1dadff {
  background: #1dadff;
}

.flat-upcoming-events .event.style2 .meta-date.bg-3caecc {
  background: #3caecc;
}

.flat-upcoming-events.style1 .flat-title .description {
  padding: 0 20%;
  letter-spacing: unset;
}

.flat-upcoming-events.style2 {
  /* background: url("../images/events/bg-event-index2.png") center center no-repeat; */
  background-size: cover;
  position: relative;
  padding: 97px 0 94px 0;
}

.flat-upcoming-events.style2 .flat-title {
  position: relative;
  margin-bottom: 96px;
}

.flat-upcoming-events.style2 .overlay {
  background: #192836;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

/* Flat Testimonials
-------------------------------------------------- */
.testimonial {
  background: #f7fbff;
  position: relative;
  padding-bottom: 45px;
  padding-top: 43px;
  -webkit-box-shadow: 6px 10px 60px -8px rgba(157, 172, 193, 0.25);
  -moz-box-shadow: 6px 10px 60px -8px rgba(157, 172, 193, 0.25);
  box-shadow: 6px 10px 60px -8px rgba(157, 172, 193, 0.25);
}

.testimonial:before {
  content: "\ea40";
  font-family: "bf-icon";
  color: rgba(243, 114, 139, 0.1);
  font-size: 75px;
  position: absolute;
  top: 72px;
  left: 126px;
}

.v2.testimonial:before {
  color: rgba(110, 121, 114, 0.1);
}

.owl-carousel .owl-item .testimonial img {
  width: auto;
  display: unset;
}

.testimonial.top-110:before {
  top: 109px;
  left: 141px;
}

.testimonial .wrap-avata-socails {
  width: 28%;
  float: left;
}

.testimonial .wrap-content {
  width: 70%;
  float: left;
  padding-left: 8px;
}

.wrap-avata-socails .avata {
  margin-bottom: 14px;
  margin-top: 6px;
}

.wrap-avata-socails .socails ul li {
  margin: 0 5px;
}

.wrap-content .info {
  margin-bottom: 30px;
}

.wrap-content .info .name {
  display: inline-block;
  margin-right: 15px;
}

.wrap-content .info .position {
  display: inline-block;
  font-weight: 700;
}

.wrap-content blockquote {
  font-size: 16px;
  padding-right: 20px;
}

.flat-testimonials .owl-dots {
  text-align: center;
}

.flat-testimonials button.owl-dot {
  background: rgba(81, 94, 111, 0.2) !important;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
}

.flat-testimonials button.owl-dot.active,
.flat-testimonials button.owl-dot:hover {
  background: #f3728b !important;
}

.flat-testimonials .owl-stage-outer {
  padding-bottom: 36px;
  padding-top: 36px;
}

.flat-testimonials {
  padding: 90px 0 89px;
}

.flat-testimonials.style4 {
  padding: 92px 0 65px;
}

.flat-testimonials.style4 .flat-title .title {
  margin-bottom: 43px;
}

.flat-testimonials.style1 .flat-title .description {
  padding: 0 10% 0 8.8%;
}

.flat-testimonials.style1 .flat-title .description,
.flat-testimonials.style2 .flat-title .description,
.flat-testimonials.style3 .flat-title .description {
  margin-bottom: 38px;
}
.flat-testimonials.style4 .flat-title .description {
  margin-bottom: 45px;
}

.flat-testimonials.style2 {
  padding: 97px 0 40px;
}

.flat-testimonials.style2.v2 {
  /* background: url('../images/testimonials/bg_teacher_say.png') center center no-repeat; */
  background-size: cover;
}

.flat-testimonials.style3 {
  padding: 95px 0 0;
  /* background: url('../images/testimonials/bg-testimonials-3.png') center center no-repeat; */
  background-size: cover;
}

.flat-testimonials.style4 .name {
  margin-right: 18px;
}

.flat-testimonials.style4 .name a {
  font-family: "Poppins";
  color: #005eea;
}

.flat-testimonials.style4 .position {
  font-size: 14px;
  font-weight: 700;
}

/* Flat Services
-------------------------------------------------- */
.flat-services {
  padding-top: 74px;
}

.flat-services .flat-title .description {
  margin-bottom: 80px;
}

.flat-services .item-content {
  background: #fff;
  padding-bottom: 25px;
}

.flat-services .item-content .img {
  float: right;
  margin-top: 26px;
}

.flat-services .item-content .text-wrap {
  padding: 53px 0 0 50px;
}

.flat-services .item-content .text-wrap .title {
  margin-bottom: 25px;
}

.flat-services .item-content .text-wrap .text {
  margin-bottom: 54px;
}

.btn-register {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-register:after {
  content: "\ea37";
  font-family: "bf-icon";
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: -19px;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-register:hover:after {
  right: -27px;
  color: #f3728b;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Flat Categories
-------------------------------------------------- */
.flat-categories {
  /* background: url("../images/bg-categories.png") center center no-repeat; */
  background-size: cover;
  padding-top: 98px;
  padding-bottom: 70px;
}

.category {
  padding: 30px 0 32px 39px;
  border: 1px solid rgba(117, 113, 109, 0.2);
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}

.category:hover,
.active.category {
  background: #ec5252;
}

.category .icon {
  font-size: 30px;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 12px;
  color: #fff;
}

.category .title a {
  font-size: 16px;
  color: #fff;
}

.flat-categories .flat-title .title {
  margin-bottom: 42px;
}

/* Flat Post
-------------------------------------------------- */
.list-meta li {
  color: rgba(81, 94, 111, 0.7);
  margin-right: 14px;
}

.list-meta li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  padding-right: 4px;
}

.list-meta li.time:before {
  content: "\f017";
}

.list-meta li.counter-like:before {
  content: "\f004";
}

.list-meta li.counter-comment:before {
  content: "\f075";
}

.list-meta li.folder:before {
  content: "\f07c";
}

.flat-button.btn-read-more {
  background-color: #f3728b;
  color: #fff;
  padding: 0 33px;
  line-height: 49px;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-family: "Poppins";
}

.flat-button.btn-read-more::before {
  background-color: #192836;
}

.flat-post {
  margin-bottom: 49px;
}

.post {
  overflow: hidden;
}

.post:not(:last-child) {
  margin-bottom: 52px;
}

.post .post-img {
  margin-bottom: 29px;
}

.post .post-content .title {
  margin-bottom: 18px;
}

.post .post-content .title a {
  font-family: "Poppins";
}

.post .list-meta {
  margin-bottom: 17px;
}

.post .post-content .text {
  margin-bottom: 35px;
}

.post .wrap-btn {
  margin-bottom: 20px;
}

/* Flat Comments
-------------------------------------------------- */
.flat-comments {
  padding-bottom: 10px;
  margin-bottom: 75px;
}

.flat-comments .title {
  font-family: "Poppins";
  font-weight: 700;
  margin-bottom: 45px;
}

.flat-comments .comment-child {
  padding-left: 44px;
}

.comment {
  padding: 15px 0 20px 25px;
  margin-bottom: 35px;
}

.comment .comment-img {
  float: left;
  margin-right: 17px;
}

.comment .comment-content {
  padding-top: 25px;
}

.comment .comment-content .name {
  display: inline-block;
  margin-right: 26px;
  margin-bottom: 41px;
}

.comment .comment-content .name a {
  font-size: 16px;
  font-family: "Poppins";
  color: #515e6f;
}

.comment .comment-content .name a:hover {
  color: #fa778a;
}

.comment .comment-content .datetime {
  font-size: 12px;
  font-weight: 700;
  color: rgba(81, 94, 111, 0.7);
}

.comment .comment-content .description {
  padding-left: 8px;
  padding-right: 40px;
  margin-bottom: 16px;
}

.comment .wrap-btn {
  padding-left: 9px;
}

.btn-reply {
  color: #fa778a;
}

.btn-reply:hover {
  color: #515e6f;
}

.btn-reply:before {
  content: "\f2f8";
  font-family: "Ionicons";
  padding-right: 7px;
}

.comment-child .comment {
  margin-bottom: 14px;
}

.comment-child .comment .comment-content {
  padding-top: 19px;
}

.comment-child .comment .comment-img {
  margin-right: 32px;
  margin-bottom: 80px;
}

.comment-child .comment .comment-content .name {
  margin-right: 15px;
  margin-bottom: 25px;
}

.comment-child .comment .comment-content .description {
  padding-right: 13px;
  margin-bottom: 5px;
}

/* Flat Form Reply
-------------------------------------------------- */
.flat-form-reply .title {
  font-family: "Poppins";
  margin-bottom: 44px;
}

.flat-form-reply input[type="text"],
.flat-form-reply input[type="email"] {
  height: 55px;
  background-color: #fff;
}

.flat-form-reply .wrap-input-all {
  margin-bottom: 31px;
}

.flat-form-reply .wrap-textarea {
  margin-bottom: 30px;
}

.flat-form-reply textarea {
  background-color: #fff;
  font-weight: 400;
}

.flat-button.btn-send {
  height: 49px;
  line-height: 49px;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 700;
  padding: 0 17px;
  border: unset;
}

/* Calculator
-------------------------------------------------- */
.form-calculator {
  margin-top: 40px;
  margin-bottom: 40px;
}

.form-calculator .bold {
  font-weight: bold;
}

.form-calculator .row {
  margin-bottom: 20px;
}

.form-calculator .title {
  display: flex;
  align-items: center;
  font-size: 20px;
  background-color: #cb824d;
  color: #ffffff;
  border-radius: 5px;
}

.form-calculator .input {
  display: flex;
}

.form-calculator .input span.right {
  width: 30px;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.form-calculator .input input[type="text"],
.form-calculator .input input[type="number"] {
  height: 55px;
  background-color: #fff;
  border-color: #98aac2;
  color: #000000;
}

.form-calculator .input input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form-calculator .input input[type="text"]:focus,
.form-calculator .input input[type="number"]:focus {
  border-color: #f64260;
}

.form-calculator .input input[type="text"]:read-only {
  background-color: #f0f0f0;
}

.form-calculator .input select {
  height: 55px;
  background-color: #fff;
  border-color: #98aac2;
  color: #000000;
}

@media (max-width: 768px) {
  .form-calculator .title {
    background-color: #ffffff;
    color: #cb824d;
  }
}
