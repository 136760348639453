/**
  *
  * Padding
  * Margin
  * Text Alight
  * Sub Columns
  * List Inline
  * Letter Spaceing
  * Transition
  *
  **/

/* Padding 
-------------------------------------------------- */
	.pd-top-0{padding-top:0 }
	.pd-top-5{padding-top:5px }
	.pd-top-10{padding-top:10px }
	.pd-top-15{padding-top:15px }
	.pd-top-20{padding-top:20px }
	.pd-top-25{padding-top:25px }
	.pd-top-30{padding-top:30px }
	.pd-top-35{padding-top:35px }
	.pd-top-40{padding-top:40px }
	.pd-top-45{padding-top:45px }
	.pd-top-50{padding-top:50px }
	.pd-top-55{padding-top:55px }
	.pd-top-60{padding-top:60px }
	.pd-top-65{padding-top:65px }
	.pd-top-70{padding-top:70px }
	.pd-top-75{padding-top:75px }
	.pd-top-80{padding-top:80px }
	.pd-top-85{padding-top:85px }
	.pd-top-90{padding-top:90px }
	.pd-top-95{padding-top:95px }
	.pd-top-100{padding-top:100px }

	.pd-bottom-0{padding-bottom:0 }
	.pd-bottom-5{padding-bottom:5px }
	.pd-bottom-10{padding-bottom:10px }
	.pd-bottom-15{padding-bottom:15px }
	.pd-bottom-20{padding-bottom:20px }
	.pd-bottom-25{padding-bottom:25px }
	.pd-bottom-30{padding-bottom:30px }
	.pd-bottom-35{padding-bottom:35px }
	.pd-bottom-40{padding-bottom:40px }
	.pd-bottom-45{padding-bottom:45px }
	.pd-bottom-50{padding-bottom:50px }
	.pd-bottom-55{padding-bottom:55px }
	.pd-bottom-60{padding-bottom:60px }
	.pd-bottom-65{padding-bottom:65px }
	.pd-bottom-70{padding-bottom:70px }
	.pd-bottom-75{padding-bottom:75px }
	.pd-bottom-80{padding-bottom:80px }
	.pd-bottom-85{padding-bottom:85px }
	.pd-bottom-90{padding-bottom:90px }
	.pd-bottom-95{padding-bottom:95px }
	.pd-bottom-100{padding-bottom:100px }

	.pd-left-0{padding-left:0 }
	.pd-left-5{padding-left:5px }
	.pd-left-10{padding-left:10px }
	.pd-left-15{padding-left:15px }
	.pd-left-20{padding-left:20px }
	.pd-left-25{padding-left:25px }
	.pd-left-30{padding-left:30px }
	.pd-left-35{padding-left:35px }
	.pd-left-30{padding-left:30px }
	.pd-left-40{padding-left:40px }
	.pd-left-45{padding-left:45px }
	.pd-left-50{padding-left:50px }
	.pd-left-55{padding-left:55px }
	.pd-left-60{padding-left:60px }
	.pd-left-65{padding-left:65px }
	.pd-left-70{padding-left:70px }
	.pd-left-75{padding-left:75px }
	.pd-left-80{padding-left:80px }
	.pd-left-85{padding-left:85px }
	.pd-left-90{padding-left:90px }
	.pd-left-95{padding-left:95px }
	.pd-left-100{padding-left:100px }

	.pd-right-0{padding-right:0 }
	.pd-right-5{padding-right:5px }
	.pd-right-10{padding-right:10px }
	.pd-right-15{padding-right:15px }
	.pd-right-20{padding-right:20px }
	.pd-right-25{padding-right:25px }
	.pd-right-30{padding-right:30px }
	.pd-right-35{padding-right:35px }
	.pd-right-40{padding-right:40px }
	.pd-right-45{padding-right:45px }
	.pd-right-50{padding-right:50px }
	.pd-right-55{padding-right:55px }
	.pd-right-60{padding-right:60px }
	.pd-right-65{padding-right:65px }
	.pd-right-70{padding-right:70px }
	.pd-right-75{padding-right:75px }
	.pd-right-80{padding-right:80px }
	.pd-right-85{padding-right:85px }
	.pd-right-90{padding-right:90px }
	.pd-right-95{padding-right:95px }
	.pd-right-100{padding-right:100px }

/* Margin 
-------------------------------------------------- */
	.mg-top-0{margin-top:0 }
	.mg-top-5{margin-top:5px }
	.mg-top-10{margin-top:10px }
	.mg-top-15{margin-top:15px }
	.mg-top-20{margin-top:20px }
	.mg-top-25{margin-top:25px }
	.mg-top-30{margin-top:30px }
	.mg-top-35{margin-top:35px }
	.mg-top-40{margin-top:40px }
	.mg-top-45{margin-top:45px }
	.mg-top-50{margin-top:50px }
	.mg-top-55{margin-top:55px }
	.mg-top-60{margin-top:60px }
	.mg-top-65{margin-top:65px }
	.mg-top-70{margin-top:70px }
	.mg-top-75{margin-top:75px }
	.mg-top-80{margin-top:80px }
	.mg-bottom-0{margin-bottom:0 }
	.mg-bottom-5{margin-bottom:5px }
	.mg-bottom-10{margin-bottom:10px }
	.mg-bottom-15{margin-bottom:15px }
	.mg-bottom-20{margin-bottom:20px }
	.mg-bottom-25{margin-bottom:25px }
	.mg-bottom-30{margin-bottom:30px }
	.mg-bottom-35{margin-bottom:35px }
	.mg-bottom-40{margin-bottom:40px }
	.mg-bottom-45{margin-bottom:45px }
	.mg-bottom-50{margin-bottom:50px }
	.mg-bottom-55{margin-bottom:55px }
	.mg-bottom-60{margin-bottom:60px }
	.mg-bottom-65{margin-bottom:65px }
	.mg-bottom-70{margin-bottom:70px }
	.mg-bottom-75{margin-bottom:75px }
	.mg-bottom-80{margin-bottom:80px }
	.mg-left-0{margin-left:0 }
	.mg-left-5{margin-left:5px }
	.mg-left-10{margin-left:10px }
	.mg-left-15{margin-left:15px }
	.mg-left-20{margin-left:20px }
	.mg-left-25{margin-left:25px }
	.mg-left-30{margin-left:30px }
	.mg-left-35{margin-left:35px }
	.mg-left-30{margin-left:30px }
	.mg-left-40{margin-left:40px }
	.mg-left-45{margin-left:45px }
	.mg-left-50{margin-left:50px }
	.mg-left-55{margin-left:55px }
	.mg-left-60{margin-left:60px }
	.mg-left-65{margin-left:65px }
	.mg-left-70{margin-left:70px }
	.mg-left-75{margin-left:75px }
	.mg-left-80{margin-left:80px }
	.mg-right-0{margin-right:0 }
	.mg-right-5{margin-right:5px }
	.mg-right-10{margin-right:10px }
	.mg-right-15{margin-right:15px }
	.mg-right-20{margin-right:20px }
	.mg-right-25{margin-right:25px }
	.mg-right-30{margin-right:30px }
	.mg-right-35{margin-right:35px }
	.mg-right-40{margin-right:40px }
	.mg-right-45{margin-right:45px }
	.mg-right-50{margin-right:50px }
	.mg-right-55{margin-right:55px }
	.mg-right-60{margin-right:60px }
	.mg-right-65{margin-right:65px }
	.mg-right-70{margin-right:70px }
	.mg-right-75{margin-right:75px }
	.mg-right-80{margin-right:80px }

/* Text Alight
-------------------------------------------------- */
	.flat-text-right {
        text-align: right;
    }

    .flat-text-left {
        text-align: left;
    }

    .flat-text-center {
        text-align: center;
    }

/* Sub Columns
-------------------------------------------------- */
	.one-of-two {
		float: left;
		width: 50%;
	}

	.one-of-three {
		float: left;
		width: 33.333%;
	}

	.one-of-four {
		float: left;
		width: 25%;
	}

	.clear-sub-columns {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

/* List Inline
-------------------------------------------------- */
	.list li {
		display: inline-block;
	}
	
/* Letter Spaceing
-------------------------------------------------- */
	.letter-spaceing-top {
		letter-spacing: 1.4px;
	}

	.letter-spaceing-001 {
		letter-spacing: 0.01em !important;
	}

/* Transition
-------------------------------------------------- */
	.transition {
		-webkit-transition: all 0.3s ease-in-out !important;
		-moz-transition: all 0.3s ease-in-out !important;
		-ms-transition: all 0.3s ease-in-out !important;
		-o-transition: all 0.3s ease-in-out !important;
		transition: all 0.3s ease-in-out !important;
	}

	.transition-img {
		-webkit-transition: all 0.8s ease-in-out;
		-moz-transition: all 0.8s ease-in-out;
		-ms-transition: all 0.8s ease-in-out;
		-o-transition: all 0.8s ease-in-out;
		transition: all 0.8s ease-in-out;
	}

/* Animation 
-------------------------------------------------- */
	.effect-animation {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

		filter: alpha(opacity=0);

		opacity: 0;

		visibility: hidden;
	}

	.effect-animation.animated {

		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

		filter: alpha(opacity=100);

		opacity: 1;

		visibility: visible;

	}
